import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { IoCameraOutline } from "react-icons/io5";
import { ImAttachment } from "react-icons/im";
import { IoIosSend } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  FilePdfOutlined,
  FileWordOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import SingleChat from "./SingleChat";
import ChatPerson from "./ChatPerson";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
import ChatPersonDirect from "./ChatPersonDirect";
import calendaricon from "../../assets/CalendarDots.svg";
import InterviewScheduleModal from "./interview/InterviewScheduleModal";
import { toast } from "sonner";
import { Spin, Tooltip } from "antd";
import { fetchUnreadMessageCount } from "../../redux-store/unread/unreadMessageSlice";
const ChatComponentdirect = ({
  Channel_details,
  OpenChat,
  userDetails,
  postDescriptionall,
}) => {
  const [conversationData, setConversationData] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sendingMsg, setSendingMsg] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const fileInputRef = useRef(null);

  const selectedLanguage = useSelector((state) => state.language.language);
  const [isInterviewScheduleModalVisible, setIsInterviewScheduleModalVisible] =
    useState(false);
  console.log(
    isInterviewScheduleModalVisible,
    "isInterviewScheduleModalVisible"
  );

  const dispatch = useDispatch();

  const updateUnreadCount = () => {
    dispatch(fetchUnreadMessageCount());
  };

  useEffect(() => {
    updateUnreadCount();
  }, []);

  // const openInterviewScheduleModal = () =>
  //   setIsInterviewScheduleModalVisible(true);
  const openInterviewScheduleModal = () => {
    setIsInterviewScheduleModalVisible(true);
  };

  const closeInterviewScheduleModal = () => {
    localStorage.removeItem("status");
    setIsInterviewScheduleModalVisible(false);
  };

  const options = translations[selectedLanguage].actions;
  const fetchConversation = async () => {
    try {
      setLoading(true);
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem("token");
      const channelId = localStorage.getItem("channelId");

      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/conversation?limit=100&channel_id=${Channel_details.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setConversationData(response.data.content.conversation);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching conversation:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    const notichannelId = localStorage.getItem("notichannelId");
    if (notichannelId) {
      localStorage.removeItem("notichannelId");
    }

    const status = localStorage.getItem("status");
    if (status === "rescheduled") {
      openInterviewScheduleModal();
    }

    fetchConversation();
    const intervalId = setInterval(fetchConversation, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handleSendMessage = async () => {
    try {
      setSendingMsg(true);
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem("token");
      const channelId = localStorage.getItem("channelId");
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const formData = new FormData();
      formData.append("message", message);
      formData.append("channel_id", Channel_details.id);

      selectedFiles.forEach((file) => {
        formData.append("files[]", file);
      });

      const response = await axios.post(
        `${baseUrl}/api/v1/customer/chat/send-message`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            "Time-Zone": timeZone,
          },
        }
      );

      setMessage("");
      setSelectedFiles([]);
      fetchConversation();
      setSendingMsg(false);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    // Check if there is more than one video file
    const videoFiles = files.filter((file) => file.type.startsWith("video/"));

    if (videoFiles.length > 1) {
      toast("You can only upload 1 video at a time.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
      return;
    }

    if (selectedFiles.length + files.length > 4) {
      toast("You can only upload a maximum of 4 files.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
      return;
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const renderFilePreview = (file) => {
    const fileType = file.type.split("/")[1];
    if (file.type.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt="Preview"
          className="object-cover rounded-lg h-full"
          style={{ maxWidth: "150px", maxHeight: "150px" }}
        />
      );
    } else if (file.type.startsWith("video/")) {
      return (
        <video
          controls
          className="object-cover rounded-lg h-full"
          style={{ maxWidth: "250px", maxHeight: "250px" }}
        >
          <source src={URL.createObjectURL(file)} type={file.type} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (fileType === "pdf") {
      return (
        <div className="flex items-center bg-white bg-opacity-30 p-2 rounded-md">
          <FilePdfOutlined className="text-red-500 mr-2" />
          {file.name}
        </div>
      );
    } else if (
      fileType === "msword" ||
      fileType === "vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return (
        <div className="flex items-center bg-white bg-opacity-30 p-2 rounded-md">
          <FileWordOutlined className="text-blue-500 mr-2" />
          {file.name}
        </div>
      );
    } else {
      return (
        <div className="flex items-center bg-white bg-opacity-30 p-2 rounded-md">
          <ImAttachment className="mr-2" />
          {file.name}
        </div>
      );
    }
  };

  return (
    <div className="w-full flex flex-col h-screen heightchat  ">
      <div>
        <hr />
        <ChatPersonDirect
          userDetails={userDetails}
          postDescriptionall={postDescriptionall}
        />
      </div>
      <SingleChat conversationData={conversationData} />
      {/* <div ref={endOfMessagesRef} /> */}
      <div className="w-auto flex flex-col mt-auto">
        <div className="w-full flex flex-wrap justify-center">
          {selectedFiles.map((file, index) => (
            <div key={index} className="relative m-2">
              {renderFilePreview(file)}
              <AiFillCloseCircle
                className="absolute top-0 right-0 text-xl cursor-pointer text-orange-400"
                onClick={() => handleRemoveFile(index)}
              />
            </div>
          ))}
        </div>
        <div className="w-full fixed"></div>
        <div className="  bottom-10  md:w-full w-full bg-white border-t-2 pt-8 justify-center items-center space-x-5 flex lg:mx-0 mx-0 md:mx-0 z-50 mb-2 pb-5  ">
          <input
            type="file"
            ref={fileInputRef}
            multiple
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <Tooltip title="You can upload up to 4 files (images or documents) at a time, and only 1 video.">
            <ImAttachment
              className="text-xl cursor-pointer"
              onClick={() => fileInputRef.current.click()}
            />
          </Tooltip>
          <div
            className={`w-9/12 bg-neutral-100 ${
              message.includes("\n") ? "rounded-md" : "rounded-full"
            } ml-auto flex items-center`}
          >
            <textarea
              placeholder="Type here..."
              className="p-2 px-9 w-10/12 bg-transparent outline-none resize-none overflow-y-auto"
              value={message}
              rows={1} // Default starting rows
              style={{
                maxHeight: "6rem", // Limit the height (approximately 3 lines)
                lineHeight: "1.5rem", // Adjust for better readability
              }}
              onChange={(e) => {
                setMessage(e.target.value);

                // Dynamically adjust height but limit to 3 lines
                e.target.style.height = "auto"; // Reset height
                const scrollHeight = e.target.scrollHeight;
                e.target.style.height = `${Math.min(scrollHeight, 96)}px`; // Cap height at 6rem (96px)
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault(); // Prevent adding new lines without Shift
                  handleSendMessage();
                }
              }}
            />
            <div
              className="bg-[#E7831D] p-2 rounded-full ml-auto hover:scale-95 hover:bg-orange-400 cursor-pointer"
              onClick={handleSendMessage}
            >
              {sendingMsg ? (
                <Spin
                  indicator={<LoadingOutlined spin />}
                  className="ml-auto w-3/4 text-neutral-100 "
                />
              ) : (
                <IoIosSend className="text-white text-2xl" />
              )}
            </div>
          </div>

          <div className="flex justify-center">
            <img
              src={calendaricon}
              alt="calendaricon"
              className="h-7 cursor-pointer hover:scale-105"
              onClick={openInterviewScheduleModal}
            />
          </div>
        </div>
      </div>
      <InterviewScheduleModal
        isVisible={isInterviewScheduleModalVisible}
        onClose={closeInterviewScheduleModal}
      />
    </div>
  );
};

export default ChatComponentdirect;
