import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Thunk to fetch unread message count
export const fetchUnreadMessageCount = createAsyncThunk(
  "unreadMessage/fetchUnreadMessageCount",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/unread_message_count`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.response_code === "default_200") {
        return response.data.content;
      } else {
        return rejectWithValue("Error fetching unread count");
      }
    } catch (err) {
      return rejectWithValue(
        err.message || "Failed to fetch unread message count"
      );
    }
  }
);

const unreadMessageSlice = createSlice({
  name: "unreadMessage",
  initialState: {
    count: 0,
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnreadMessageCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUnreadMessageCount.fulfilled, (state, action) => {
        state.count = action.payload;
        state.loading = false;
      })
      .addCase(fetchUnreadMessageCount.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default unreadMessageSlice.reducer;
