// src/components/LanguageModal.js
import React, { useState, useRef, useEffect, Fragment } from "react";
import { Modal } from "antd";
import { Combobox, Transition } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../redux-store/languageSlice";
import axios from "axios";
import { Toaster, toast } from "sonner";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin } from "antd";
import { IoClose } from "react-icons/io5";

const Languages = [
  "English",
  "Spanish",
  // Add more languages as needed
];

const LanguageModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.language);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const comboboxRef = useRef(null);

  useEffect(() => {
    // Focus the ComboboxInput when the modal opens
    comboboxRef.current && comboboxRef.current.focus();
  }, []);

  const handleSave = () => {
    setLoading(true);

    setLoading(false);
    dispatch(setLanguage(selectedLanguage));
    closeModal();
    setLoading(false);
  };

  return (
    <Modal
      visible={true}
      onCancel={closeModal}
      footer={null}
      closeIcon={<IoClose className="text-neutral-50" />}
    >
      <div className="w-full h-auto py-5 px-10 flex flex-col space-y-4">
        <h1 className="text-lg text-neutral-700 font-bold">Language</h1>
        <div className="w-full">
          <Combobox
            value={selectedLanguage}
            onChange={(value) => dispatch(setLanguage(value))}
            onClose={() => setQuery("")}
          >
            <div className="relative mt-1">
              <div className="relative w-full cursor-default p-2 border-2 bg-white text-left rounded-full sm:text-sm">
                <Combobox.Input
                  aria-label="Language"
                  value={selectedLanguage}
                  onChange={(event) => setQuery(event.target.value)}
                  placeholder="Type to search..."
                  className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-neutral-900 focus:ring-0 outline-none"
                  ref={comboboxRef}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <FiChevronDown
                    className="h-5 w-5 text-neutral-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
              >
                <Combobox.Options className="border-neutral-300 border-2 rounded-2xl overflow-y-auto mt-5 max-h-60 scrollbar-hide">
                  {Languages.filter((item) =>
                    item.toLowerCase().includes(query.toLowerCase())
                  ).map((item, index) => (
                    <Combobox.Option
                      key={index}
                      value={item}
                      className="text-neutral-900 px-4 py-2 cursor-pointer hover:bg-neutral-200 "
                    >
                      {item}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>

        <button
          className="w-full p-3 text-white font-bold bg-[#404041] hover:scale-95 rounded-full"
          onClick={handleSave}
        >
          {loading && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </Modal>
  );
};

export default LanguageModal;
