import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { IoClose } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { Toaster, toast } from "sonner";
import axios from "axios";
import avatar from "../../assets/avatarpost.png";
import { useSelector } from "react-redux";
import { IoIosCheckmarkCircle } from "react-icons/io";
import translations from "../../languages";
import { GoDotFill } from "react-icons/go";
import MainLogoLoader from "../loading/MainLogoLoader";

const EditPostModalComponentpost = ({
  iseditModalVisible,
  setIseditModalVisible,
  fetchPosts,
  fetchData,
  setLoadingpost,
  setPostLimit,
}) => {
  const postDetails = useSelector((state) => state.post.postDetails);
  const [userAvatar, setUserAvatar] = useState("");
  const [username, setUsername] = useState("");
  const [loadingava, setLoadingava] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [description, setDescription] = useState(postDetails.description || "");
  const [mediaFiless, setmediaFiless] = useState([]);
  console.log(mediaFiless, "mediaFiless");
  const [loading, setLoading] = useState(false);
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].buttons;

  useEffect(() => {
    if (postDetails) {
      setDescription(postDetails.description || "");

      const existingFiles = [
        ...(postDetails.photos || []),
        ...(postDetails.videos || []),
      ].map((file) => ({
        ...file,
        file: file.file,
        isFile: false,
      }));

      setmediaFiless(existingFiles);
    }
  }, [postDetails]);

  const handleMediaUpload = (e) => {
    const files = Array.from(e.target.files);

    if (files.length > 0) {
      const uploadedFiles = files.map((file) => ({
        file: file,
        type: file.type.startsWith("image") ? "image" : "video",
        isFile: true, // This marks them as newly uploaded files
      }));

      // Update state to include the newly uploaded files
      setmediaFiless((prevFiles) => [...prevFiles, ...uploadedFiles]);
    }
  };

  const removeMediaFile = (index) => {
    setmediaFiless((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseURL}/api/v1/user/post/update/${postDetails.id}`;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formData = new FormData();
    setLoading(true);
    formData.append("description", description);

    mediaFiless.forEach((file) => {
      if (file.isFile && file.file instanceof File) {
        if (file.type === "image") {
          formData.append("identity_images[]", file.file);
        } else if (file.type === "video") {
          formData.append("video[]", file.file);
        }
      } else if (!file.isFile) {
        if (file.type === "image") {
          formData.append("existing_identity_images[]", file.file);
        } else if (file.type === "video") {
          formData.append("existing_video[]", file.file);
        }
      }
    });

    try {
      setLoadingpost(true);
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Time-Zone": userTimeZone,
        },
      });

      if (response.status === 200) {
        setLoading(false);
        toast("Post updated successfully!", {
          style: {
            backgroundColor: "#333", // neutral background
            color: "#fff", // White text
          },
        });

        fetchPosts(true);
        setLoadingpost(false);

        setIseditModalVisible(false);
      } else {
        // setLoading(false);

        setLoadingpost(false);
        toast("Failed to update post", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
        setIseditModalVisible(false);
      }
    } catch (error) {
      // setLoading(false);
      setLoadingpost(false);
      toast("Failed to update post", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
      console.error("Error updating post:", error);
      setIseditModalVisible(false);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoadingava(true);
      try {
        const token = localStorage.getItem("token");
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const userDetailsUrl = `${baseURL}/api/v1/user/details`;
        const response = await axios.get(userDetailsUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserDetails(response.data.content);
        setUserAvatar(response.data.content.profile_image);
        setUsername(
          `${response.data.content.first_name} ${response.data.content.last_name}`
        );

        localStorage.setItem("userId", response.data.content.id);
        setLoadingava(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setLoadingava(false);
      }
    };

    fetchUserDetails();
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    const formattedString = string.replace(/-/g, " ");
    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <>
      <Modal
        visible={iseditModalVisible}
        onCancel={() => setIseditModalVisible(false)}
        footer={null}
        width={700}
        closeIcon={<IoClose className="text-neutral-50" />}
      >
        <div className="flex flex-col p-5">
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white/50 bg-opacity-50 z-50">
              <MainLogoLoader />
            </div>
          )}
          hello
          <div className="flex flex-col sm:flex-row items-start">
            {loadingava ? (
              <div className="w-12 h-12 rounded-full p-0 object-cover text-[#E7831D] mb-3 sm:mb-0 bg-neutral-300 animate-pulse mt-3"></div>
            ) : (
              <div className="flex justify-start w-full">
                <img
                  className={`rounded-full p-0 justify-start text-[#E7831D] cursor-pointer object-cover w-12 h-12 ${
                    userDetails.type?.toLowerCase() === "employer"
                      ? "ring-4 ring-[#E7831D]"
                      : "ring-4 ring-neutral-700"
                  }`}
                  src={
                    userAvatar !== "default.png" && userAvatar
                      ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${userAvatar}`
                      : avatar
                  }
                  alt="Avatar"
                />

                <div className="ml-3">
                  <h1 className={`text-lg font-bold text-[#5B5B5B]`}>
                    {username}
                  </h1>
                  <div className="flex space-x-2">
                    <span>{userDetails.username}</span>
                    <span
                      className={`lg:text-sm md:text-sm text-xs  flex items-center ${
                        userDetails.type === "job-seeker"
                          ? "text-black"
                          : "text-[#E7831D]"
                      }`}
                    >
                      <GoDotFill className="ml-1 text-xs mr-1" />
                      {capitalizeFirstLetter(userDetails.type)}
                      <IoIosCheckmarkCircle className="ml-1 text-lg" />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <textarea
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={texts.descriptionPlaceholder}
            className="border-0 outline-none p-5 w-full z-50 bg-transparent px-[3.7rem] scrollbar-hide max-h-96"
          />
          <div className="flex flex-wrap mt-3 gap-2 px-[3.7rem]">
            {mediaFiless.map((media, index) => {
              const mediaURL = media.isFile
                ? URL.createObjectURL(media.file) // For newly uploaded files
                : `https://bocaendboca.s3.us-east-2.amazonaws.com/${media.file}`; // For existing files
              // For existing files

              return (
                <div key={index} className="relative">
                  {media.type === "image" ? (
                    <img
                      src={mediaURL}
                      alt={`upload-${index}`}
                      className=" object-cover w-36 h-36 rounded-md"
                    />
                  ) : media.type === "video" ? (
                    <video
                      src={mediaURL}
                      controls
                      className="w-20 h-20 object-cover rounded-md"
                    />
                  ) : null}
                  <FaTimes
                    onClick={() => removeMediaFile(index)}
                    className="absolute text-2xl top-1 right-1 text-white cursor-pointer bg-red-600 rounded-full p-1"
                  />
                </div>
              );
            })}
          </div>
          <div className="flex w-full h-auto mt-5 justify-end items-center gap-4">
            <div className="flex items-center">
              <input
                type="file"
                accept="image/*,video/*"
                className="hidden"
                id="media-upload"
                onChange={handleMediaUpload}
                multiple
              />

              <label htmlFor="media-upload">
                <MdOutlineAddPhotoAlternate className="text-2xl text-neutral-500 cursor-pointer" />
              </label>
            </div>

            <button
              onClick={handleSubmit}
              className="bg-[#404041] p-3 text-center text-white font-bold rounded-full px-10 hover:bg-[#545455] hover:scale-95 text-lg"
              disabled={loading}
            >
              Update
            </button>
          </div>
        </div>
      </Modal>

      <Toaster richColors closeButton />
    </>
  );
};

export default EditPostModalComponentpost;
