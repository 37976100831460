import React from "react";

const CardShimmer = () => {
  return (
    <div className="p-5">
      <div className="h-96 w-full shadow-md rounded-xl flex justify-center flex-col items-center text-center">
        <div className="w-16 h-16 bg-neutral-200 rounded-full animate-pulse"></div>
        <div className="rounded-full h-6 w-56 bg-neutral-200 animate-pulse mt-5"></div>
        <div className="rounded-full h-3 w-[30rem] bg-neutral-200 animate-pulse mt-6"></div>
        <div className="rounded-full h-3 w-[28rem] bg-neutral-200 animate-pulse mt-2"></div>
        <div className="rounded-full h-3 w-[32rem] bg-neutral-200 animate-pulse mt-2"></div>
        <div className="rounded-full h-3 w-52 bg-neutral-200 animate-pulse mt-2"></div>

        <div className="rounded-full h-6 w-44 bg-neutral-200 animate-pulse mt-5"></div>
        <div className="rounded-full h-10 w-2/3 bg-neutral-200 animate-pulse mt-5"></div>
      </div>
    </div>
  );
};

export default CardShimmer;
