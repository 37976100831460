import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InterviewTypeDropdown({
  currentInterviewType,
  setCurrentInterviewType,
  heading,
}) {
  const selectedLanguage = useSelector((state) => state.language.language);

  const interviewTypes = [
    "Phone Interview",
    "In-Person Interview",
    "Video Interview",
  ];

  const handleSelectInterviewType = (type) => {
    setCurrentInterviewType(type);
  };

  return (
    <Menu as="div" className="relative inline-block w-full">
      <p className="text-[15.85px] font-bold mt-3 mb-1 text-[#424242]">
        {heading}
      </p>
      <Menu.Button className="inline-flex justify-between w-full px-4 py-2 bg-white text-sm font-medium text-neutral-700 hover:bg-neutral-50 border-2 rounded-full">
        {currentInterviewType}
        <FaChevronDown className="ml-2 -mr-1 h-5 w-5 text-neutral-400" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 mt-2 w-full  bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {interviewTypes.map((type) => (
            <Menu.Item key={type}>
              {({ active }) => (
                <button
                  onClick={() => handleSelectInterviewType(type)}
                  className={classNames(
                    active ? "bg-[#424242] text-white" : "text-neutral-900",
                    "block px-4 py-2 text-sm w-full text-left hover:bg-neutral-700 "
                  )}
                >
                  {type}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
