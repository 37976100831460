import { Fragment } from "react";
import moment from "moment";
import { TimePicker } from "antd"; // Import TimePicker from AntD
import { FaChevronDown } from "react-icons/fa";

const TimeOptionTwo = ({ selectedTime, setSelectedTime, heading }) => {
  const handleSelectTime = (time) => {
    if (time) {
      const formattedTime = time.format("HH:mm:ss");
      setSelectedTime(formattedTime); // Update selected time immediately when the user selects a time
    } else {
      setSelectedTime(null); // Clear time if no selection
    }
  };

  const handleOpenChange = (open) => {
    if (open) {
      setSelectedTime(null); // Clear time when the picker is opened
    }
  };

  return (
    <div className="relative inline-block w-full">
      <p className="text-[15.85px] font-bold mt-3 mb-1 text-[#424242]">
        {heading} <span className="text-neutral-600 text-xs">(Optional)</span>
      </p>
      <TimePicker
        value={selectedTime ? moment(selectedTime, "HH:mm:ss") : null} // Set the value directly from selectedTime
        onChange={handleSelectTime} // Update time on selection
        onOpenChange={handleOpenChange} // Handle opening of the TimePicker
        format="hh:mm A"
        className="w-full px-4 py-2 bg-white text-sm font-medium text-neutral-700 hover:bg-neutral-50  hover:border-neutral-200 border-2 rounded-full focus:outline-none focus:ring-0 focus:border-neutral-300"
        suffixIcon={<FaChevronDown className="text-neutral-400 " />}
        dropdownClassName="custom-dropdown"
        allowClear={true}
        // showNow={false}
        clearIcon={<span className="text-sm">Cancel</span>}
      />
    </div>
  );
};

export default TimeOptionTwo;
