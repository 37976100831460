import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import ReceiveingMessage from "./chatbox/ReceiveingMessage";
import SendingMessage from "./chatbox/SendingMessage";

const SingleChat = ({ conversationData }) => {
  const storedUserId = localStorage.getItem("userId");
  const endOfMessagesRef = useRef(null);
  const [messageCount, setMessageCount] = useState(conversationData.length);

  // Function to format the date and time of a message
  const formatDate = (created_at) => {
    const createdAt = moment(created_at).local(); // Convert to local timezone
    return createdAt.format("dddd, MMMM D, hh:mm A"); // Format the date with am/pm
  };

  // Check if conversationData exists and is an array before mapping
  if (!conversationData || !Array.isArray(conversationData)) {
    return (
      <div className="h-[27rem] flex justify-center items-center text-orange-300">
        No messages yet
      </div>
    );
  }

  // Scroll to bottom when a new message is added
  useEffect(() => {
    if (conversationData.length > messageCount) {
      endOfMessagesRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
      setMessageCount(conversationData.length);
    }
  }, [conversationData, messageCount]);

  // Function to check if the date has changed
  const shouldShowDate = (currentDate, previousDate) => {
    return !previousDate || !currentDate.isSame(previousDate, "day");
  };

  let previousDate = null;

  // Render messages with date and time headers when the day changes
  return (
    <div className="h-[100vh] overflow-y-auto scrollbar-hide">
      {conversationData.map((chat, index) => {
        const currentDate = moment(chat.created_at);
        const showDate = shouldShowDate(currentDate, previousDate);

        // If the date has changed, update the previousDate variable
        if (showDate) {
          previousDate = currentDate;
        }

        return (
          <React.Fragment key={index}>
            <div>
              {showDate && (
                <div className="text-center text-xs bg-[#f0efef] text-neutral-500 w-2/5 justify-center items-center mx-auto rounded-full p-1 my-1">
                  {/* Show the formatted date and time */}
                  {formatDate(chat.created_at)}
                </div>
              )}
              {chat.user_id === storedUserId ? (
                <SendingMessage chat={chat} />
              ) : (
                <ReceiveingMessage chat={chat} />
              )}
            </div>
          </React.Fragment>
        );
      })}
      <div ref={endOfMessagesRef} />
    </div>
  );
};

export default SingleChat;
