import React, { useState } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import LeftAlignedAuthFormHeading from "../components/mini-components/LeftAlignedAuthFormHeading";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import HampsterLoader from "../components/loading/HampsterLoader";
import { Toaster, toast } from "sonner";
import { PiEyeClosed } from "react-icons/pi";
import { MdRemoveRedEye } from "react-icons/md";
import { Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const NewPassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return "Password must be at least 8 characters long.";
    }
    if (!hasUpperCase) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!hasLowerCase) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!hasNumber) {
      return "Password must contain at least one number.";
    }
    if (!hasSpecialChar) {
      return "Password must contain at least one special character.";
    }
    return "";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error messages as the user types
  };

  const handleSubmit = async () => {
    const { newPassword, confirmPassword } = formData;

    // Validate newPassword
    const passwordError = validatePassword(newPassword);
    if (passwordError) {
      setErrors({ ...errors, newPassword: passwordError });
      return;
    }

    // Validate confirmPassword
    if (newPassword !== confirmPassword) {
      setErrors({ ...errors, confirmPassword: "Passwords do not match." });
      return;
    }

    setLoading(true);
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const { identity, identity_type } = JSON.parse(
        localStorage.getItem("identity")
      );
      const otp = localStorage.getItem("otp");
      const response = await axios.put(
        `${baseUrl}/api/v1/user/forget-password/reset`,
        {
          identity,
          identity_type,
          otp,
          password: newPassword,
          confirm_password: confirmPassword,
        }
      );

      if (response.status === 200) {
        setLoading(false);

        toast("Password reset successful.", {
          style: {
            backgroundColor: "#333", // neutral background
            color: "#fff", // White text
          },
        });
        navigate("/login");
      } else {
        toast("Failed to update password.", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast("An error occurred while resetting your password.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
      console.error("Error:", error.message);
    }
  };

  const togglePasswordVisibility = (type) => {
    if (type === "newPassword") {
      setShowPassword(!showPassword);
    } else if (type === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <div className="flex justify-center items-center w-full h-screen overflow-y-auto bg-[#f9f6f6]">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/30 bg-opacity-50 z-50">
          <HampsterLoader />
        </div>
      )}
      <div className="bg-white lg:w-4/12 md:w-8/12 w-11/12 rounded-[2rem] p-10 shadow-xl flex text-center flex-col mt-10 overflow-y-auto scrollbar-hide max-h-[90%]">
        <div className="flex w-full">
          <IoArrowBackCircleOutline
            className="text-3xl text-black justify-start items-start text-left cursor-pointer hover:scale-110"
            onClick={() => navigate("/login")}
          />
        </div>
        <LeftAlignedAuthFormHeading text="New Password" />

        <div className="bg-[#f2f2f2] rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full flex justify-between items-center px-2">
          <input
            type={showPassword ? "text" : "password"}
            name="newPassword"
            value={formData.newPassword}
            onChange={handleInputChange}
            className="w-10/12 h-full p-3 bg-transparent outline-none"
            placeholder="Enter new password"
          />
          {showPassword ? (
            <MdRemoveRedEye
              className="text-neutral-700 text-2xl justify-center items-center cursor-pointer"
              onClick={() => togglePasswordVisibility("newPassword")}
            />
          ) : (
            <PiEyeClosed
              className="text-neutral-700 text-2xl justify-center items-center cursor-pointer"
              onClick={() => togglePasswordVisibility("newPassword")}
            />
          )}
        </div>
        {errors.newPassword && (
          <p className="text-[#E7831D] text-xs mt-1">{errors.newPassword}</p>
        )}

        <div className="bg-[#f2f2f2] mt-3 rounded-full mb-1 border-2 border-[#818181] outline-none w-full flex justify-between items-center px-2">
          <input
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            className="w-10/12 h-full p-3 bg-transparent outline-none"
            placeholder="Confirm new password"
          />
          {showConfirmPassword ? (
            <MdRemoveRedEye
              className="text-neutral-700 text-2xl justify-center items-center cursor-pointer"
              onClick={() => togglePasswordVisibility("confirmPassword")}
            />
          ) : (
            <PiEyeClosed
              className="text-neutral-700 text-2xl justify-center items-center cursor-pointer"
              onClick={() => togglePasswordVisibility("confirmPassword")}
            />
          )}
        </div>
        {errors.confirmPassword && (
          <p className="text-[#E7831D] text-xs mt-1">
            {errors.confirmPassword}
          </p>
        )}

        <button
          className="p-3 bg-[#404041] hover:bg-[#585858] text-white rounded-full shadow-sm text-lg hover:scale-95 hover:shadow-lg lg:mt-4 mt-4"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}
          Submit
        </button>
      </div>
    </div>
  );
};

export default NewPassword;
