import React from "react";
import { Modal } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import logo from "../assets/mainlogonew.png";
import { useDispatch, useSelector } from "react-redux";
import translations from "../languages";
import { IoClose } from "react-icons/io5";
const PleaseLoginModal = ({
  visible,

  onCancel,
  title = "Please Log In",
  content = "You need to log in to access this feature.",
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].loginmodal;
  const handleCancel = () => {
    if (location.pathname === "/") {
      navigate("/");
    } else if (location.pathname.includes("/explore")) {
      navigate("/explore");
    } else if (location.pathname.includes("/settings")) {
      navigate("/settings");
    } else if (location.pathname.startsWith("/user/")) {
      navigate(`/user/${id}`); // Navigate to the same user path dynamically
    } else {
      navigate(-1);
    }

    // Call the onCancel prop function if provided
    if (onCancel) onCancel();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      title={null}
      footer={null}
      closeIcon={<IoClose className="text-neutral-50" />}
    >
      <div className="flex flex-col space-y-5 items-center text-center lg:p-5 md:p-5 p-3 h-[27rem]">
        <p class="font-inter font-semibold text-2xl leading-7 text-center px-12 py-5 mt-2">
          {texts.text}
        </p>

        <img
          src={logo}
          alt="logo"
          className="w-full h-auto px-16 lg:py-8  md:py-10 p-10 mb-4 "
        />
        <button
          className="h-14  bg-[#404041] text-white font-bold rounded-full shadow-sm text-xl  hover:scale-95 hover:shadow-lg w-10/12 lg:mt-4 mt-5"
          onClick={() => navigate("/login")}
        >
          {texts.button}
        </button>
      </div>
    </Modal>
  );
};

export default PleaseLoginModal;
