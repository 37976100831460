import React, { useState, useEffect, useRef } from "react";
import { BsThreeDots } from "react-icons/bs";
import ProfileBanner from "./ProfileBanner";
import Postscopy from "../components/homepage/Postscopy";
import postdata from "./postData";
import { fetchUserDetails } from "../apis";
import MainLogoLoader from "../components/loading/MainLogoLoader";
import axios from "axios"; // Import Axios
import ProfileShimmer from "../components/loading/ProfileShimmer";
import { useDispatch, useSelector } from "react-redux";
import translations from "../languages";
import bblogo from "../assets/276pxlogo.svg";
import mobilebarsvg from "../assets/barsvgmobtab.svg";
import { useNavigate } from "react-router-dom";
import PostShimmer from "../components/loading/PostShimmer";
import { Toaster, toast } from "sonner";
import PostForMyProfile from "../components/homepage/PostForMyProfile";

const ProfileMain = ({ setSidebarOpen }) => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingpost, setLoadingpost] = useState(true);
  const [userId, setUserId] = useState("");
  const [postList, setPostList] = useState([]);
  const [page, setPage] = useState(1); // Track the current page for pagination
  const searchText = useSelector((state) => state.search.searchText);
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].headings;

  const fetchUserData = async () => {
    try {
      const userDetailsData = await fetchUserDetails();
      setUserDetails(userDetailsData.content);
      setUserId(userDetailsData.content.id);
      fetchData(1); // Start with the first page
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setLoading(false);
    }
  };

  const [offset, setOffset] = useState(1); // Initialize offset to 1
  const fetchData = async () => {
    setLoadingpost(true);
    try {
      const token = localStorage.getItem("token");
      const iduser = localStorage.getItem("userId");

      const postListResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/list?limit=10&offset=${offset}&user_id=${iduser}`, // Use the current offset
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Append the new posts to the existing list
      setPostList((prevPosts) => [
        ...prevPosts,
        ...postListResponse.data.content.data,
      ]);
      setLoadingpost(false);
    } catch (error) {
      console.error("Error fetching post list:", error);
      setLoadingpost(false);
    }
  };

  const loadMorePosts = () => {
    setOffset((prevOffset) => prevOffset + 1);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // Scroll detection to trigger load more posts when user reaches the bottom
  const observer = useRef(null);

  useEffect(() => {
    const handleObserver = (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        loadMorePosts();
      }
    };

    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 1.0, // Trigger when 100% of the last post is visible
    };

    observer.current = new IntersectionObserver(handleObserver, options);
    const lastPostElement = document.querySelector("#last-post");
    if (lastPostElement) {
      observer.current.observe(lastPostElement);
    }

    return () => {
      if (observer.current && lastPostElement) {
        observer.current.unobserve(lastPostElement);
      }
    };
  }, [postList]); // Re-run when postList changes

  const updateProfileImage = async (profileImage) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("profile_image", profileImage);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/update/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fetchUserData();
      }
    } catch (error) {
      console.error("Error updating profile image:", error);
    }
  };

  const updateBannerImage = async (bannerImage) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("banner_image", bannerImage);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/update/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // If the update is successful, fetch user data again to display the latest details
      if (response.status === 200) {
        fetchUserData();
      }
    } catch (error) {
      console.error("Error updating banner image:", error);
    }
  };
  const removeProfileImage = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/user_profile_image_delete`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fetchUserData();
      }
    } catch (error) {
      console.error("Error removing profile image:", error);
      setLoading(false);
    }
  };
  const removeBannerImage = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/user_banner_image_delete`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fetchUserData();
      }
    } catch (error) {
      console.error("Error removing profile image:", error);
      setLoading(false);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/delete/${postId}`,

        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast(" Post deleted successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
      fetchData(true);
    } catch (error) {
      console.error("Error deleted post:", error);
      toast("Error deleted post. Please try again later.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    }
  };

  useEffect(() => {
    if (searchText.length > 0) {
      navigate("/explore");
    }
  }, [searchText, navigate]);

  return (
    <div className="scrollbar-hide w-full paddingy mobile-top">
      <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
        <div className="absolute left-0">
          <img
            src={mobilebarsvg}
            alt="logo"
            className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
        <div className="flex justify-center w-full">
          <img src={bblogo} className="h-8 hidelarge" />
        </div>
      </div>

      <div className="flex ">
        <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide">
          {texts.myProfile}
        </h1>
        {/* <BsThreeDots className="ml-auto text-3xl mr-2" /> */}
      </div>

      <hr />
      {loading ? (
        <ProfileShimmer />
      ) : (
        <ProfileBanner
          removeProfileImage={removeProfileImage}
          removeBannerImage={removeBannerImage}
          userDetails={userDetails}
          updateProfileImage={updateProfileImage}
          updateBannerImage={updateBannerImage}
        />
      )}
      <hr />
      {loadingpost ? (
        <PostShimmer />
      ) : (
        <PostForMyProfile
          postdatas={postList}
          fetchData={fetchData}
          handleDeletePost={handleDeletePost}
          setLoadingpost={setLoadingpost}
        />
      )}
    </div>
  );
};

export default ProfileMain;
