import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setActiveNotificationCount } from "../../redux-store/activeNotifications/activeNotificationSlice";
import NotificationCards from "./NotificationCards";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import NotificationShimmer from "./NotificationShimmer";

import translations from "../../languages";

const Notification = ({ list, height }) => {
  const [showAll, setShowAll] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].headings;
  const action = translations[selectedLanguage].actions;
  const dispatch = useDispatch();

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const fetchNotificationCount = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/notification/notificationcount`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const activeCount = response.data.content || 0;
      dispatch(setActiveNotificationCount(activeCount));
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  const fetchNotifications = async () => {
    try {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/notification?limit=100&offset=1`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const notificationsData = response.data.content.data;

      // Filter out notifications where sender_user.id matches userId
      const filteredNotifications = notificationsData.filter(
        (notification) => notification.sender_user.id !== userId
      );

      setNotifications(filteredNotifications);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
    fetchNotificationCount(); // Fetch notification count separately
  }, [dispatch]);

  if (loading) {
    return <NotificationShimmer />;
  }

  return (
    <div
      className={`p-0 px-5 flex flex-col bg-transparent overflow-y-auto notification-container z-30 mt-3`}
      style={{ maxHeight: height }}
    >
      {notifications.length !== 0 && (
        <>
          <span className="text-lg font-bold text-black mb-1 h-10 mt-1 flex justify-between items-center">
            {texts.notifications}
            {showAll && (
              <span
                className="text-sm text-[#E7831D] cursor-pointer hover:text-[#e98e33]"
                onClick={toggleShowAll}
              >
                {action.Showless}
              </span>
            )}
          </span>
          <div className="flex-1 overflow-auto p-0 m-0 mb-44 scrollbar-hide z-30">
            {notifications.length > 0 ? (
              <NotificationCards
                notifications={notifications}
                list={list}
                showAll={showAll}
                setShowAll={setShowAll}
                toggleShowAll={toggleShowAll}
              />
            ) : (
              <div className="flex items-center justify-center w-full"></div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Notification;
