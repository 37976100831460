import React, { useState } from "react";
import { motion } from "framer-motion";
import { Modal } from "antd";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { LuCalendarDays } from "react-icons/lu";
import moment from "moment";
import {
  DownloadOutlined,
  FilePdfOutlined,
  FileWordOutlined,
} from "@ant-design/icons"; // Import Ant Design icons

const SendingMessage = ({ key, chat }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0); // Track selected image index for carousel

  const handleOpenModal = (index) => {
    setSelectedImageIndex(index); // Set the selected image index for carousel
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  const formattedDate = chat.interview_date
    ? moment(chat.interview_date).format("D MMMM YYYY")
    : "";

  // Update the time formatting logic
  const formattedTime = chat.interview_time
    ? moment(chat.interview_time, "HH:mm").format("h:mm a") + " CST"
    : chat.interview_time_values
    ? chat.interview_time_values
        .split(",")
        .map(
          (time) => moment(time.trim(), "HH:mm:ss").format("h:mm a") + " CST"
        )
    : "";

  const renderFiles = (files) => {
    if (files.length === 1) {
      const file = files[0];
      if (
        file.file_type === "jpg" ||
        file.file_type === "png" ||
        file.file_type === "jpeg"
      ) {
        return (
          <div className="w-full h-auto aspect-w-1 aspect-h-1">
            <img
              src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${file.stored_file_name}`}
              alt={file.original_file_name}
              className="object-cover w-full h-full rounded-lg"
              style={{ maxWidth: "400px", maxHeight: "500px" }}
              onClick={() => handleOpenModal(0)} // Open modal on image click
            />
          </div>
        );
      } else {
        return (
          <div className="flex items-center bg-white bg-opacity-30 p-2 rounded-md">
            <a
              href={`https://bocaendboca.s3.us-east-2.amazonaws.com/${file.stored_file_name}`}
              download={file.original_file_name}
              className="text-white flex items-center"
            >
              {file.file_type === "pdf" ? (
                <FilePdfOutlined className="mr-2" />
              ) : (
                <FileWordOutlined className="mr-2" />
              )}
              {file.original_file_name}
            </a>
          </div>
        );
      }
    } else if (files.length > 1) {
      const gridItems = files.map((file, index) => {
        if (
          file.file_type === "jpg" ||
          file.file_type === "png" ||
          file.file_type === "jpeg"
        ) {
          return (
            <div
              key={index}
              className="relative w-full aspect-w-1 aspect-h-1 p-0"
            >
              <img
                src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${file.stored_file_name}`}
                alt={file.original_file_name}
                className="object-cover w-full h-full rounded-lg cursor-pointer"
                style={{ maxWidth: "300px", maxHeight: "200px" }}
                onClick={() => handleOpenModal(index)} // Open modal on image click
              />
              {index === 3 && files.length > 4 && (
                <div
                  className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center cursor-pointer rounded-lg"
                  onClick={() => handleOpenModal(index)}
                >
                  <span className="text-white text-2xl">
                    +{files.length - 4}
                  </span>
                </div>
              )}
            </div>
          );
        } else if (file.file_type === "mp4" || file.file_type === "mov") {
          // Check for video file types
          return (
            <div
              key={index}
              className="relative w-full aspect-w-16 aspect-h-9 p-0"
            >
              <video
                controls
                className="object-cover w-full h-full rounded-lg cursor-pointer"
                style={{ maxWidth: "300px", maxHeight: "200px" }}
                onClick={() => handleOpenModal(index)} // Open modal on video click
              >
                <source
                  src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${file.stored_file_name}`}
                  type={`video/${
                    file.file_type === "mp4" ? "mp4" : "quicktime"
                  }`}
                />
                Your browser does not support the video tag.
              </video>
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className="flex items-center bg-white bg-opacity-30 rounded-md  p-3"
            >
              <a
                href={`https://bocaendboca.s3.us-east-2.amazonaws.com/${file.stored_file_name}`}
                download={file.original_file_name}
                className="text-white flex items-center"
                target="_blank"
              >
                {file.file_type === "pdf" ? (
                  <FilePdfOutlined className="mr-2" />
                ) : (
                  <FileWordOutlined className="mr-2" />
                )}
                {file.original_file_name}
              </a>
            </div>
          );
        }
      });
      return <div className="grid grid-cols-2 gap-2">{gridItems}</div>;
    }
  };

  const customArrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 20px)",
    width: 40,
    height: 40,
    cursor: "pointer",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
    border: "none",
    outline: "none",
    color: "#fff",
    fontSize: "1.6rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div className="w-full">
      <div className="p-3 flex justify-end" key={key}>
        {chat?.message?.includes("interview scheduled") &&
        chat?.interview_confirm !== 2 ? (
          <div className={chat.interview_confirm === 1 ? "mr-0" : "mr-0"}>
            <div
              className={`  ${
                chat.user.type?.toLowerCase() === "employer"
                  ? "bg-[#E7831D]"
                  : "bg-[#404041]"
              } p-5 rounded-xl flex flex-col max-w-[355px] ml-3`}
            >
              <div className="flex flex-row ml-auto ">
                <LuCalendarDays className="mr-2 text-white text-6xl w-1/3" />
                {chat.interview_confirm === 1 ? ( // Check if interview is confirmed
                  <div>
                    <p className="text-white text-sm ">
                      Thank you. I confirm we have a phone interview on{" "}
                      {formattedDate} at {formattedTime}. We look forward to it.
                    </p>
                  </div>
                ) : (
                  // Existing logic for scheduled interview
                  <>
                    {chat.interview_type === "Video Interview" ? (
                      <div>
                        <p className="text-white text-sm ">
                          Are you available for a video interview on{" "}
                          {formattedDate}? 
                        </p>
                        <p className="text-white text-sm mt-4">
                          Please click the time below to confirm. 
                        </p>
                      </div>
                    ) : (
                      <div>
                        <p className="text-white text-sm">
                          {chat.interview_type === "In-Person Interview" &&
                            `Are you available for an in-person interview on ${formattedDate}?`}
                          {chat.interview_type === "Phone Interview" &&
                            `Are you available for a phone interview on ${formattedDate}?`}
                        </p>
                        <p className="text-white text-sm mt-4">
                          Please click the time below to confirm. 
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
              {/* Remove the button rendering for confirmed interviews */}
              {chat.interview_confirm !== 1 && // Only render if not confirmed
                (Array.isArray(formattedTime) ? (
                  // Multiple time slots in a row
                  <div className="flex gap-2 mt-3">
                    {formattedTime.map((time, index) => (
                      <button
                        key={index}
                        disabled
                        className="flex-1 rounded-full bg-neutral-600/20 p-2 text-white text-center text-sm"
                      >
                        {time}
                      </button>
                    ))}
                  </div>
                ) : (
                  // Single time slot
                  <button
                    disabled
                    className="w-full rounded-full bg-neutral-600/20 p-2 mt-3 text-white text-center text-sm"
                  >
                    {formattedTime}
                  </button>
                ))}
            </div>
          </div>
        ) : (
          <motion.div
            initial={{ y: 50, scale: 0.5, opacity: 0 }}
            animate={{ y: 0, scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className={`p-3 text-md text-white rounded-xl mb-4 ml-5 max-w-[70%] ${
              chat.user.type?.toLowerCase() === "employer"
                ? "bg-[#E7831D]"
                : "bg-[#404041]"
            }`}
            style={{
              minWidth: "50px",

              minHeight: "30px",
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            {chat?.interview_confirm !== 2 ? (
              <p>{chat?.message}</p>
            ) : (
              <p className="text-sm">
                {" "}
                The {chat.interview_type} on {formattedDate} at {formattedTime}{" "}
                has been canceled.{" "}
              </p>
            )}

            {chat.conversation_files && chat.conversation_files.length > 0 && (
              <div className="">{renderFiles(chat.conversation_files)}</div>
            )}
          </motion.div>
        )}
      </div>

      {/* Replace Modal with a full-screen preview */}
      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-[999999]">
          <button
            onClick={handleCloseModal}
            className="absolute top-4 right-4 text-white text-3xl z-[9999999]"
          >
            &times; {/* Close button */}
          </button>
          <Carousel
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            selectedItem={selectedImageIndex}
            onChange={(index) => setSelectedImageIndex(index)}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{ ...customArrowStyles, left: 15 }}
                >
                  &#8249;
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{ ...customArrowStyles, right: 15 }}
                >
                  &#8250;
                </button>
              )
            }
            dotStyle={{
              background: "rgba(255, 255, 255, 0.8)",
              width: 12,
              height: 12,
              margin: "0 5px",
              borderRadius: "50%",
              border: "none",
              outline: "none",
            }}
            dotActiveStyle={{
              background: "#fff",
              width: 12,
              height: 12,
              margin: "0 5px",
              borderRadius: "50%",
              border: "none",
              outline: "none",
            }}
          >
            {chat.conversation_files
              .filter(
                (file) =>
                  file.file_type === "jpg" ||
                  file.file_type === "png" ||
                  file.file_type === "jpeg"
              )
              .map((file, index) => (
                <div
                  key={index}
                  className="relative w-full h-full  flex justify-center items-center p-20"
                >
                  <img
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${file.stored_file_name}`}
                    alt={file.original_file_name}
                    className="ml-0 object-scale-down justify-center items-center"
                  />
                </div>
              ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default SendingMessage;
