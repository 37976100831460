import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { BsThreeDots } from "react-icons/bs";

const MenuOptions = ({
  userExists,
  post,
  setIsLoginModalVisible,
  handleEditPost,
  handleDeletePost,
  handleReportPost,
  handleBookmarkClick,
  options,
  loading,
  handleRepost,
}) => {
  const [myPost, setMyPost] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const MyId = localStorage.getItem("userId");
    setMyPost(MyId);
  }, []);

  const getTimeAgo = (created_at) => {
    const now = moment();
    const createdAt = moment(created_at);

    const monthsDiff = now.diff(createdAt, "months");
    const daysDiff = now.diff(createdAt, "days");
    const hoursDiff = now.diff(createdAt, "hours");
    const minutesDiff = now.diff(createdAt, "minutes");
    const secondsDiff = now.diff(createdAt, "seconds");

    if (monthsDiff > 0) {
      return `${monthsDiff} month${monthsDiff > 1 ? "s" : ""} ago`;
    } else if (daysDiff > 0) {
      return `${daysDiff} day${daysDiff > 1 ? "s" : ""} ago`;
    } else if (hoursDiff > 0) {
      return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
    } else if (minutesDiff > 0) {
      return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
    } else {
      return `${secondsDiff} second${secondsDiff > 1 ? "s" : ""} ago`;
    }
  };

  return (
    <>
      <div className=" flex justify-end text-xs flex-col ml-auto relative -top-1 z-50 ">
        <Popover>
          {({ open }) => {
            setIsMenuOpen(open);
            return (
              <div className="w-full flex justify-end">
                <PopoverButton className="border-none focus:outline-none">
                  {!userExists && !loading ? (
                    <div></div>
                  ) : (
                    <BsThreeDots className="text-3xl ml-auto cursor-pointer" />
                  )}
                </PopoverButton>
                <Transition
                  enter="transition ease-out duration-75"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <PopoverPanel className="right-2 origin-top-right w-36  border border-black/30 bg-white  text-sm/6 text-black   focus:outline-none shadow-md absolute">
                    {localStorage.getItem("userId") === post?.user?.id &&
                      (post?.link_post_details == null ||
                      (Object.keys(post?.link_post_details).length === 0 &&
                        post?.link_post_details.constructor === Object) ? (
                        <button
                          className="text-neutral-900 group flex items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start whitespace-nowrap"
                          onClick={() => handleRepost(post?.id)}
                        >
                          {options?.repostPost}
                        </button>
                      ) : null)}

                    {localStorage.getItem("userId") !== post?.user?.id && (
                      <button
                        className="text-neutral-900 group flex  items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start whitespace-nowrap "
                        onClick={() => handleReportPost(post?.id)}
                      >
                        {options?.reportPost}
                      </button>
                    )}
                    {localStorage.getItem("userId") === post?.user?.id &&
                      (post?.link_post_details == null ||
                        (Object.keys(post?.link_post_details).length === 0 &&
                          post?.link_post_details.constructor === Object)) && (
                        <button
                          className="text-neutral-900 group flex items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start whitespace-nowrap"
                          onClick={() => handleEditPost(post)}
                        >
                          {options?.editPost}
                        </button>
                      )}

                    {localStorage.getItem("userId") === post?.user?.id && (
                      <button
                        className="text-neutral-900 group flex  items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start whitespace-nowrap"
                        onClick={() => handleDeletePost(post?.id)}
                      >
                        {options?.deletePost}
                      </button>
                    )}
                    {localStorage.getItem("userId") !== post?.user?.id && (
                      <button
                        onClick={() => handleBookmarkClick(post?.user?.id)}
                        className="text-neutral-900 group flex  items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start"
                      >
                        {options?.bookmarkUser}
                      </button>
                    )}
                  </PopoverPanel>
                </Transition>
              </div>
            );
          }}
        </Popover>
        <p className="ml-auto">{getTimeAgo(post.created_at)}</p>
      </div>
    </>
  );
};

export default MenuOptions;
