import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DropdownIconMenu = ({
  icon: Icon = HiOutlineDotsCircleHorizontal,
  options,
}) => {
  return (
    <Menu as="div" className="relative ml-6 md:hidden">
      <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-neutral-400 hover:text-neutral-500">
        <span className="sr-only">Open menu</span>
        <Icon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-neutral-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {options.map((option, index) => (
            <div key={index} className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active
                        ? "bg-neutral-100 text-neutral-900"
                        : "text-neutral-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    {option}
                  </a>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropdownIconMenu;
