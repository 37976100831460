import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import "./modal.css";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setPostDetails } from "../../redux-store/postEdit/postSlice";
import { setSearchText } from "../../redux-store/postSearch/searchSlice";
import { IoIosBookmark } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import PostModal from "./PostModal";
import { Modal } from "antd";
import { IoBookmarkSharp } from "react-icons/io5";
import { MdCloseFullscreen } from "react-icons/md";
import { Spin } from "antd";
import Loading from "../loading/Loading";
import { Link, useLocation, useNavigate } from "react-router-dom";
import avatar from "../../assets/lady.jpg";
import { FaRegBookmark } from "react-icons/fa";
const BASE_URL = "https://bocaenboca.goodcoderz.com/";
import { GoDotFill } from "react-icons/go";
import commentIcon from "../../assets/comment.svg";
import bookmark from "../../assets/Bookmarks.svg";
import share from "../../assets/share.svg";
import { LuShare } from "react-icons/lu";
import commentWhite from "../../assets/commentwhite.svg";
import bookMarkwhite from "../../assets/bookmarkwhite.svg";
import PleaseLoginModal from "../../pages/PleaseLoginModal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import User from "./User";
import { IoExpand, IoArrowBack, IoArrowForward } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import MenuOptions from "./MenuOptions";
import translations from "../../languages";
import EditPostModalComponentpost from "../mini-components/EditPostModalComponentpost";
import { LoadingOutlined } from "@ant-design/icons";
import SharePostPopUp from "../share/SharePostPopUp";
import { toast } from "sonner";
import EditPostModalComponent from "../mini-components/EditPostModalComponent";
import ImageGallery from "../mini-components/ImageGallery";
import VideoGallery from "../mini-components/VideoGallery";
import MediaModal from "../mini-components/MediaModal";
import PostDescription from "../mini-components/PostDescription";

const Repost = ({
  Repostdatas,
  handleBookmark,
  handleReportPost,
  createChatChannel,
  channelLoading,
  handleDeletePost,
  loading,
  bookmarkedPosts,
  setFetching,
  fetchPosts,
  setPostLimit,
  fetchData,
  setLoadingpost,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [bookMarkFetching, setBookMarkFetching] = useState({});
  const [error, setError] = useState(null);
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [modalData, setModalData] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const [deletedBookmark, setDeletedBookmark] = useState(false);
  const [copymodalVisible, setcopyModalVisible] = useState(false);
  console.log(copymodalVisible, "copymodalVisible");
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(null);
  const selectedLanguage = useSelector((state) => state.language.language);
  console.log(selectedLanguage, "selectedLanguage");
  const [iseditModalVisible, setIseditModalVisible] = useState(false);
  const [url, setUrl] = useState("");
  console.log(url, "url");
  const texts = translations[selectedLanguage].types;
  const options = translations[selectedLanguage].actions;

  const handleTagClick = (tag) => {
    dispatch(setSearchText(tag));
  };

  const getTimeAgo = (created_at) => {
    const now = moment();
    const createdAt = moment(created_at);

    const monthsDiff = now.diff(createdAt, "months");
    const daysDiff = now.diff(createdAt, "days");
    const hoursDiff = now.diff(createdAt, "hours");
    const minutesDiff = now.diff(createdAt, "minutes");
    const secondsDiff = now.diff(createdAt, "seconds");

    if (monthsDiff > 0) {
      return `${monthsDiff} month${monthsDiff > 1 ? "s" : ""} ago`;
    } else if (daysDiff > 0) {
      return `${daysDiff} day${daysDiff > 1 ? "s" : ""} ago`;
    } else if (hoursDiff > 0) {
      return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
    } else if (minutesDiff > 0) {
      return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
    } else {
      return `${secondsDiff} second${secondsDiff > 1 ? "s" : ""} ago`;
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);

  const openModal = (Repostdatas, index) => {
    setModalData(Repostdatas);
    setModalIndex(index); // Store the index
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const isBookmarkPage = location.pathname.includes("/bookmark");

  return (
    <div className="relative -top-5">
      <div
        className={`w-full scrollbar-hide  ${
          isBookmarkPage
            ? "hover:scale-105 duration-300 cursor-pointer px-2"
            : ""
        }`}
        style={{
          backgroundColor:
            Repostdatas.user?.username === "@premium"
              ? "#8b551e"
              : Repostdatas.user?.username === "@Test"
              ? "#7e7d7e"
              : "white",

          color:
            Repostdatas.user?.username === "@premium" ||
            Repostdatas.user?.username === "@Test"
              ? "white"
              : "black",
          padding:
            Repostdatas.user?.username === "@premium"
              ? "0"
              : Repostdatas.user?.username === "@Test"
              ? "0"
              : "0 0rem",
          borderRadius:
            Repostdatas.user?.username === "@premium" ||
            Repostdatas.user?.username === "@Test"
              ? "0"
              : "0.5rem",
          paddingTop: Repostdatas.user?.username === "@premium" ? "2px" : "0",
          paddingBottom:
            Repostdatas.user?.username === "@premium" ? "2px" : "0",
          marginBottom: Repostdatas.user?.username === "@Test" ? "10px" : "0",
        }}
      >
        <PostDescription
          description={Repostdatas.description}
          handleTagClick={handleTagClick}
        />
        <div className="">
          {" "}
          <ImageGallery post={Repostdatas} openModal={openModal} />
        </div>

        {/* <VideoGallery Repostdatas={Repostdatas} openModal={openModal} /> */}

        {Repostdatas.user?.username !== "@premium" &&
          Repostdatas.user?.username !== "@Test"}
      </div>

      <MediaModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        modalData={modalData}
        handleTagClick={handleTagClick}
      />

      {isLoginModalVisible && (
        <PleaseLoginModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}
      {copymodalVisible && (
        <SharePostPopUp
          url={url}
          copymodalVisible={copymodalVisible}
          setcopyModalVisible={setcopyModalVisible}
        />
      )}
    </div>
  );
};

export default Repost;
