import React, { useEffect, useState } from "react";
import { FaLinkedin, FaFacebookF, FaGoogle } from "react-icons/fa";
import { BsApple } from "react-icons/bs";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { Toaster, toast } from "sonner";
import SccialLoading from "../loading/SccialLoading";

const SocialLogin = () => {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_BASE_URL; // Ensure this is set in your `.env` file

  const [loading, setLoading] = useState(false);
  const url = `${window.location.origin}/linkedin/callback`;
  console.log(url, "url");

  // Google Login
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      setLoading(true);
      try {
        const userInfo = await axios.get(
          `https://www.googleapis.com/oauth2/v3/userinfo`,
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );

        const {
          given_name: first_name,
          family_name: last_name,
          email,
          sub: unique_id,
        } = userInfo.data;
        const requestBody = {
          first_name,
          last_name,
          email,
          unique_id,
          medium: "google",
          type: "job_seeker",
          fcm_token: "",
        };

        const apiResponse = await axios.post(
          `${baseURL}/api/v1/customer/auth/social-registration`,
          requestBody
        );
        const { token } = apiResponse.data.content;

        localStorage.setItem("token", token);
        navigate("/");
      } catch (error) {
        console.error("Error during Google login:", error);
        toast("Google login failed. Please try again.", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
      } finally {
        setLoading(false);
      }
    },
    onError: () => {
      toast("Google login failed. Please try again.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    },
  });

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    scope: "openid+profile+w_member_social+email",
    redirectUri: `${window.location.origin}/linkedin/callback`,

    onSuccess: async (code) => {
      setLoading(true);
      try {
        console.log("LinkedIn Login Successful! Authorization Code:", code);

        const userInfoResponse = await axios.get(
          `https://api.linkedin.com/v2/me`,
          {
            headers: {
              Authorization: `Bearer ${code}`,
              "Access-Control-Allow-Origin": window.location.origin,
            },
          }
        );

        const first_name = userInfoResponse.data.localizedFirstName;
        const last_name = userInfoResponse.data.localizedLastName;
        const email = emailResponse.data.elements[0]["handle~"].emailAddress;
        const unique_id = userInfoResponse.data.id;

        const requestBody = {
          first_name,
          last_name,
          email,
          unique_id,
          medium: "linkedin",
          type: "job_seeker",
        };

        const apiResponse = await axios.post(
          `${baseURL}/api/v1/customer/auth/social-registration`,
          requestBody
        );

        const { token } = apiResponse.data.content;

        navigate("/");

        toast("LinkedIn login successful!", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
      } catch (error) {
        console.error("Error during LinkedIn login:", error);
        toast("LinkedIn login failed. Please try again.", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      console.error("LinkedIn Login Error:", error);
      toast("LinkedIn login failed. Please try again.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    },
  });

  if (loading) {
    return <SccialLoading />;
  }

  return (
    <div className="w-full h-auto flex justify-between items-center px-10 mt-5">
      <FaLinkedin
        onClick={linkedInLogin} // Trigger LinkedIn login
        className="text-4xl text-[#414040] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer"
      />
      <FaGoogle
        onClick={handleGoogleLogin} // Trigger Google login
        className="text-4xl text-[#414040] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer"
      />
      <BsApple className="text-4xl text-[#404041] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer" />
      <FaFacebookF className="text-4xl text-[#414040] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer" />
      <Toaster position="top-right" />
    </div>
  );
};

export default SocialLogin;
