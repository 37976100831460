import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Assuming you're using react-router for routing
import axios from "axios";
import ChatComponentdirect from "../components/messagePage/ChatComponentdirect";
import ShimmerChatPersonDirect from "../components/loading/ShimmerChatPersonDirect";
import ShimmerChatsList from "../components/loading/ShimmerChatsList";
import { MdOutlineErrorOutline } from "react-icons/md";

const DirectChat = () => {
  const { id, userId } = useParams();
  const [selectedChat, setSelectedChat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [postDescriptionall, setPostDescription] = useState("");
  const [userDetails, setUserDetails] = useState(null);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchChatData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/conversation?channel_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const channelDetails = response.data.content.Channel_details;

      if (channelDetails) {
        setSelectedChat(channelDetails);
        setPostDescription(channelDetails.post.description);
      } else {
        setSelectedChat(null);
        setPostDescription("");
      }
    } catch (error) {
      console.error("Error fetching chat data:", error);
      setError("Failed to load chat data.");
    }
  };

  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/api/v1/user/details/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUserDetails(response.data.content);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setError("Failed to load user details.");
    }
  };

  useEffect(() => {
    setLoading(true);
    setError(null);

    const fetchData = async () => {
      await fetchChatData();
      await fetchUserDetails();
      setLoading(false);
    };

    fetchData();
  }, [id, userId]);

  if (loading) {
    return (
      <div>
        <ShimmerChatPersonDirect />
        <ShimmerChatsList />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen  text-neutral-600">
        <MdOutlineErrorOutline className="text-6xl mb-4" />
        <h2 className="text-2xl font-semibold mb-2">
          Oops! Something went wrong!
        </h2>
      </div>
    );
  }

  return (
    <div className="w-full h-screen">
      <ChatComponentdirect
        Channel_details={selectedChat}
        postDescriptionall={postDescriptionall}
        OpenChat={() => {}}
        userDetails={userDetails}
      />
    </div>
  );
};

export default DirectChat;
