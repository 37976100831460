import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const PostDescription = ({ description, handleTagClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const renderDescription = () => {
    if (!description) return <span className="text-neutral-500"></span>;

    return description.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line.split(/(\s+)/).map((part, i) =>
          part.startsWith("#") ? (
            <span
              key={i}
              className="text-[#E7831D] font-bold cursor-pointer transition-transform ease-in-out duration-300 text-sm sm:text-base md:text-md"
              onClick={() => handleTagClick(part)}
            >
              {part}
            </span>
          ) : (
            <span key={i} className="text-sm sm:text-base md:text-md">
              {part}
            </span>
          )
        )}
        <br />
      </React.Fragment>
    ));
  };

  useEffect(() => {
    if (contentRef.current && description) {
      // Check if the content height exceeds the limit (e.g., 150px)
      setIsOverflowing(contentRef.current.scrollHeight > 150);
    } else {
      setIsOverflowing(false);
    }
  }, [description]);

  return (
    <div className="mt-5 mx-9">
      <AnimatePresence>
        <motion.div
          ref={contentRef}
          initial={{ height: "auto" }}
          animate={{ height: isExpanded || !isOverflowing ? "auto" : 150 }}
          exit={{ height: "auto" }}
          className={`overflow-hidden ${
            isOverflowing && !isExpanded ? "max-h-[150px]" : ""
          }`}
          transition={{ duration: 0.3 }}
        >
          {renderDescription()}
        </motion.div>
      </AnimatePresence>
      {isOverflowing && !isExpanded && (
        <button
          onClick={toggleExpanded}
          className="text-[#E7831D] relative font-semibold mt-2 cursor-pointer flex justify-center text-center items-center"
        >
          Show more <IoIosArrowDown className="mt-1 ml-1" />
        </button>
      )}
      {isOverflowing && isExpanded && (
        <button
          onClick={toggleExpanded}
          className="text-[#E7831D] font-semibold mt-2 cursor-pointer flex justify-center text-center items-center"
        >
          Show less <IoIosArrowUp className="mt-1 ml-1" />
        </button>
      )}
    </div>
  );
};

export default PostDescription;
