import React from "react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { BsThreeDots } from "react-icons/bs";
import translations from "../languages";

const UserActionsMenu = ({ onBookmark, onBlock }) => {
  const selectedLanguage = "English";
  const options = translations[selectedLanguage].actions;

  return (
    <div className="flex justify-end text-xs flex-col ml-auto relative top-0 z-50">
      <Popover>
        {({ open }) => (
          <>
            <PopoverButton className="border-none focus:outline-none">
              <BsThreeDots className="text-3xl ml-auto cursor-pointer" />
            </PopoverButton>
            <Transition
              enter="transition ease-out duration-75"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <PopoverPanel className="right-2 origin-top-right w-36 border border-black/30 bg-white p-1 text-sm text-black shadow-md absolute">
                <button
                  onClick={onBookmark}
                  className="text-neutral-900 group flex items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start"
                >
                  {options.bookmarkUser}
                </button>
                <button
                  onClick={onBlock}
                  className="text-neutral-900 group flex items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start"
                >
                  {options.blockUser}
                </button>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default UserActionsMenu;
