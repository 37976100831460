import React, { useState } from "react";
import { Modal, Input } from "antd";
import axios from "axios";
import { Toaster, toast } from "sonner";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin } from "antd";
import { IoClose } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneNumberModal = ({ closeModal }) => {
  const [phoneNumber, setPhoneNumber] = useState("+1");
  console.log("phoneNumber", phoneNumber);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const handleSave = async () => {
    setLoading(true);
    setError("");
    if (!validatePhoneNumber(phoneNumber)) {
      setLoading(false);
      return;
    }
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");
      await axios.post(
        `${baseURL}/api/v1/user/update/${userId}`,
        {
          phone: phoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast("Phone number updated successfully!", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
      closeModal();
    } catch (error) {
      toast("Failed to update phone number. Please try again.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    } finally {
      setLoading(false);
    }
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\+1\d{10}$/;

    if (!phoneRegex.test(number)) {
      setError("Please enter a valid phone number (e.g., +11234567890).");
      return false;
    }
    setError("");
    return true;
  };

  const handleInputChange = (value) => {
    setPhoneNumber(`${"+"}${value}`);
    validatePhoneNumber(`${"+"}${value}`);
  };

  return (
    <Modal
      visible={true}
      onCancel={closeModal}
      footer={null}
      closeIcon={<IoClose className="text-neutral-50" />}
    >
      <div className="w-full h-auto py-5 px-10 flex flex-col space-y-4">
        <h1 className="text-lg text-neutral-700 font-bold">
          Change Phone Number
        </h1>

        {/* Phone input with country code */}
        <PhoneInput
          country={"us"}
          value={phoneNumber}
          onChange={handleInputChange}
          inputProps={{
            className:
              "w-full border-2 border-neutral-300 rounded-full py-4 pl-14 pr-20 text-sm leading-5 text-neutral-900 focus:ring-0 outline-none",
            placeholder: "Enter new phone number",
          }}
        />
        {error && <p className="text-red-500 text-sm">{error}</p>}

        <button
          className={`w-full p-3 text-white font-bold bg-[#404041] hover:scale-95 rounded-full ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handleSave}
          disabled={loading}
        >
          {loading && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </Modal>
  );
};

export default PhoneNumberModal;
