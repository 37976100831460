import React, { useState } from "react";
import { Checkbox } from "@headlessui/react";
import { FaCheck } from "react-icons/fa";

const RememberMeCheckbox = ({ text, checked, onChange }) => {
  return (
    <div className="flex space-x-3 justify-start items-center p-2">
      <Checkbox
        checked={checked}
        onChange={onChange}
        className="group size-6 rounded-md bg-black/10 p-1 ring-1 ring-white/15 ring-inset data-[checked]:bg-[#E7831D]"
      >
        <FaCheck className="hidden size-4 fill-white group-data-[checked]:block " />
      </Checkbox>
      <span className="text-sm">{text} </span>
    </div>
  );
};

export default RememberMeCheckbox;
