import React, { useState } from "react";
import { FaRegEdit, FaUserCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { LuShare } from "react-icons/lu";
import { IoQrCodeOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { IoPersonOutline } from "react-icons/io5";
import avatar from "../assets/avatarpost.png";
import lady from "../assets/lady.jpg";
import axios from "axios"; // Import Axios
import profileicon from "../assets/Profile.svg";
import bannaricon from "../assets/HeaderImg.svg";
import moment from "moment";
import { duration } from "moment";
import { motion } from "framer-motion";
import { setSearchText } from "../redux-store/postSearch/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import translations from "../languages";
import ShareProfilePopUp from "../components/mini-components/ShareProfilePopUp";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
const ProfileBanner = ({
  userDetails,
  updateProfileImage,
  removeBannerImage,
  updateBannerImage,
  removeProfileImage,
}) => {
  const selectedLanguage = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const [profileShareModalVisible, setProfileShareModalVisible] =
    useState(false);
  const [profileUrl, setprofileUrl] = useState("");
  const texts = translations[selectedLanguage].profileExtras;
  const [isImageOverlayVisible, setImageOverlayVisible] = useState(false);
  // const [menuVisible, setMenuVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpenbanner, setIsMenuOpenbanner] = useState(false);
  // Toggle the menu visibility
  const handleProfileImageClickToggle = () => {
    setMenuVisible(!menuVisible);
  };

  const handleshare = (id) => {
    setprofileUrl(`${window.location.origin}/user/${id}`);
    setProfileShareModalVisible(true);
    // navigate(`/post/${id}`);
  };
  // Function to handle profile image update
  const handleProfileImageUpdate = (e) => {
    const profileImage = e.target.files[0];
    updateProfileImage(profileImage);
  };

  const handleBannerImageUpdate = (e) => {
    const bannerImage = e.target.files[0];
    updateBannerImage(bannerImage);
  };

  const handleTagClick = (tag) => {
    dispatch(setSearchText(tag));
  };

  const handleProfileImageClick = () => {
    setImageOverlayVisible(true); // Show the image overlay
  };

  const closeOverlay = () => {
    setImageOverlayVisible(false); // Hide the image overlay
  };
  const parseTextWithHash = (text) => {
    if (!text.includes("#")) {
      return text;
    }
    const parts = text.split(/(#\S+)/g); // Split by # and keep the symbol with the word
    return parts.map((part, index) =>
      part.startsWith("#") ? (
        <span
          key={index}
          className="text-[#E7831D] font-bold cursor-pointer"
          onClick={() => handleTagClick(part)}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };
  function formatResponseTime(seconds) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);

    let formatted = "";
    if (days > 0) {
      formatted += `${days}d `;
    }
    if (hours > 0) {
      formatted += `${hours}h `;
    }
    if (minutes > 0) {
      formatted += `${minutes}min`;
    }
    return formatted ? `${formatted} ${texts.responseTime}` : "";
  }
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  };
  return (
    <>
      <div className="mb-2 w-full">
        <div className="relative w-full">
          <img
            className="h-32 w-full object-cover lg:h-48 z-[99999] filter grayscale"
            src={
              userDetails.banner_image !== "default.png" &&
              userDetails.banner_image
                ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.banner_image}`
                : bannaricon
            }
            alt=""
            style={{
              filter: "neutralscale(100%)",
            }}
          />
          <div className="w-full">
            <div
              className="absolute bg-neutral-500 p-2 px-2.5 rounded-full bottom-5 right-10 shadow-md cursor-pointer "
              style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
            >
              <Popover>
                {({ open }) => {
                  setIsMenuOpenbanner(open);
                  return (
                    <>
                      <PopoverButton className="border-none focus:outline-none">
                        <FaRegEdit className="text-xl text-neutral-50 ml-1 mt-1" />
                      </PopoverButton>
                      <Transition
                        enter="transition ease-out duration-75"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <PopoverPanel className=" right-6 border border-black/30 bg-white text-sm/6 text-black focus:outline-none shadow-md absolute">
                          {userDetails.banner_image && (
                            <button
                              className="text-neutral-900 group flex items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start whitespace-nowrap"
                              onClick={() => removeBannerImage()}
                            >
                              Remove banner image
                            </button>
                          )}
                          {userDetails.banner_image && (
                            <label
                              htmlFor="bannerImageInput"
                              className="text-neutral-900 group flex items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start whitespace-nowrap"
                            >
                              <input
                                type="file"
                                id="bannerImageInput"
                                className="hidden"
                                onChange={handleBannerImageUpdate}
                              />
                              Change banner image
                            </label>
                          )}
                          {!userDetails.banner_image && (
                            <label
                              htmlFor="bannerImageInput"
                              className="text-neutral-900 group flex items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start whitespace-nowrap"
                            >
                              <input
                                type="file"
                                id="bannerImageInput"
                                className="hidden"
                                onChange={handleBannerImageUpdate}
                              />
                              Upload banner image
                            </label>
                          )}
                        </PopoverPanel>
                      </Transition>
                    </>
                  );
                }}
              </Popover>
            </div>
          </div>
        </div>
        <div className="mx-4   px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex relative">
              {userDetails.profile_image ? (
                userDetails.type === "job-seeker" ? (
                  <img
                    className=" rounded-full  h-32 w-32 object-cover ring-4 ring-neutral-500"
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.profile_image}`}
                    alt=""
                    onClick={handleProfileImageClick}
                  />
                ) : (
                  <img
                    className=" rounded-full  h-32 w-32 object-cover ring-4 ring-[#E7831D] "
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.profile_image}`}
                    alt=""
                    onClick={handleProfileImageClick}
                  />
                )
              ) : (
                <FaUserCircle
                  className={`h-32 w-32 bg-white text-[2rem] rounded-full p-0 object-cover text-neutral-300 cursor-pointer ${
                    userDetails?.type?.toLowerCase() === "employer"
                      ? "ring-4 ring-[#E7831D]"
                      : "ring-4 ring-neutral-700"
                  }`}
                  onClick={handleProfileImageClick}
                />
              )}
              <label
                htmlFor="profileImageInput"
                className={`absolute p-2 rounded-full bottom-5 z-[99999999999999999] left-20 ml-5 shadow-md cursor-pointer  ${
                  userDetails.type?.toLowerCase() === "employer"
                    ? "bg-[#E7831D]"
                    : "bg-neutral-500"
                }`}
                style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
              >
                <div className=" flex justify-end text-xs flex-col  relative top-0 z-50  ">
                  <Popover>
                    {({ open }) => {
                      setIsMenuOpen(open);
                      return (
                        <>
                          <PopoverButton className="border-none focus:outline-none">
                            <FaRegEdit className="text-xl text-neutral-50 z-[99999999999999999] ml-1 mt-0.5" />
                          </PopoverButton>
                          <Transition
                            enter="transition ease-out duration-75"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <PopoverPanel className=" origin-top-right  border border-black/30 bg-white  text-sm/6 text-black [--anchor-gap:var(--spacing-1)] focus:outline-none shadow-md absolute">
                              {userDetails.profile_image && (
                                <button
                                  className="text-neutral-900 group flex items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start whitespace-nowrap "
                                  onClick={() => removeProfileImage()}
                                >
                                  Remove profile image
                                </button>
                              )}
                              {userDetails.profile_image && (
                                <label
                                  htmlFor="profileImageInput"
                                  className="text-neutral-900 group flex  items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start  whitespace-nowrap"
                                >
                                  <input
                                    type="file"
                                    id="profileImageInput"
                                    className="hidden z-[9999999999999999]"
                                    onChange={handleProfileImageUpdate}
                                  />
                                  Change profile image
                                </label>
                              )}
                              {!userDetails.profile_image && (
                                <label
                                  htmlFor="profileImageInput"
                                  className="text-neutral-900 group flex  items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start  whitespace-nowrap"
                                >
                                  <input
                                    type="file"
                                    id="profileImageInput"
                                    className="hidden z-[9999999999999999]"
                                    onChange={handleProfileImageUpdate}
                                  />
                                  Upload profile image
                                </label>
                              )}
                            </PopoverPanel>
                          </Transition>
                        </>
                      );
                    }}
                  </Popover>
                </div>
              </label>
            </div>
          </div>
          <div className="mt-3  min-w-0 flex-1 ">
            <h1 className="truncate text-2xl font-bold text-neutral-900">
              {userDetails.first_name} {userDetails.last_name}
            </h1>
          </div>
          <div className="flex items-center">
            <span className="text-sm text-neutral-600">
              {userDetails.username}
            </span>
            {userDetails.type?.toLowerCase() === "employer" && (
              <span className="text-sm text-[#E7831D]   flex items-center ml-2">
                {capitalizeFirstLetter(userDetails.type)}{" "}
                <FaCheckCircle className="ml-2" />
              </span>
            )}
            {userDetails.type === "job-seeker" && (
              <span className="text-sm text-neutral-600   flex items-center ml-2">
                {capitalizeFirstLetter(userDetails.type)}{" "}
                <FaCheckCircle className="ml-2" />
              </span>
            )}
            <div className="ml-auto">
              <LuShare
                className="text-2xl cursor-pointer hover:scale-105  "
                onClick={() => handleshare(userDetails.id)}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-3 mt-1 ">
            <div className="flex gap-3 text-neutral-700 mb-3">
              {userDetails.no_of_post} {texts.posts}{" "}
              <span>
                {" "}
                {userDetails.no_of_bookmark} {texts.bookmarks}
              </span>{" "}
              {userDetails.avg_chat_response !== null && (
                <span>{formatResponseTime(userDetails.avg_chat_response)}</span>
              )}
            </div>
            {userDetails.city && (
              <div className="flex ">
                <CiLocationOn className="text-xl mr-2 " />
                {userDetails.city && userDetails.city.name}
                {", "}
                {userDetails.state && userDetails.state.name}
                {", "}
                {userDetails.country && userDetails.country.name}
              </div>
            )}

            {userDetails.about_me && (
              <div className="flex ">
                <img
                  src={profileicon}
                  alt="profile icon"
                  className="mr-2 h-5 w-5"
                />
                {texts.about}
              </div>
            )}
            {userDetails.about_me && (
              <p className="mx-7 text-justify">
                {parseTextWithHash(userDetails.about_me)}
              </p>
            )}
          </div>
        </div>
      </div>{" "}
      {isImageOverlayVisible && (
        <motion.div
          className="fixed inset-0 z-[99999999999999999999] flex items-center flex-col  bg-neutral-700 bg-opacity-30 backdrop-blur-md"
          onClick={closeOverlay}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            className="relative"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <img
              className="h-52 w-52 md:h-72 md:w-72 lg:h-72 lg:w-72 rounded-full object-cover mt-24"
              src={
                userDetails.profile_image !== "default.png" &&
                userDetails.profile_image
                  ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.profile_image}`
                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoHAKWHhA6UuX-UPdizhi5K4IsByBpEJMX8Q&s"
              }
              alt="Profile"
            />
          </motion.div>
          <motion.div
            className="relative lg:w-96 md:w-72 w-64"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            transition={{ duration: 0.9, ease: "easeInOut" }}
          >
            <div className="absolute mt-10 left-1/2 w-full transform -translate-x-1/2 flex  gap-4 p-2 md:p-5 lg:p-3 bg-white rounded-xl shadow-lg">
              <div className="flex items-center flex-col justify-center gap-2 w-1/2">
                <div className="p-2 border-2 border-neutral-400 rounded-full">
                  <IoQrCodeOutline
                    className="text-[#E7831D] text-3xl cursor-pointer hover:scale-105"
                    onClick={() => handleshare(userDetails.id)}
                  />
                </div>

                <button
                  className="text-neutral-700 font-semibold text-sm "
                  onClick={() => handleshare(userDetails.id)}
                >
                  QR Code
                </button>
              </div>
              <div className="flex items-center flex-col justify-center gap-2 w-1/2">
                <div className="p-2 border-2 border-neutral-400 rounded-full">
                  <LuShare
                    className="text-[#E7831D] text-3xl cursor-pointer hover:scale-105"
                    onClick={() => handleshare(userDetails.id)}
                  />
                </div>

                <button
                  className="text-neutral-700 font-semibold text-sm"
                  onClick={() => handleshare(userDetails.id)}
                >
                  Share Profile
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
      {profileShareModalVisible && (
        <ShareProfilePopUp
          profileShareModalVisible={profileShareModalVisible}
          setProfileShareModalVisible={setProfileShareModalVisible}
          profileUrl={profileUrl}
        />
      )}
    </>
  );
};

export default ProfileBanner;
