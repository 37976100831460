import React, { useState, useEffect } from "react";
import { Modal, Input } from "antd";
import axios from "axios";
import { Toaster, toast } from "sonner";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin } from "antd";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
const { TextArea } = Input;

const AboutMeModal = ({ closeModal, data, userData }) => {
  const [aboutMe, setAboutMe] = useState(""); // Initial empty string state
  const [loading, setLoading] = useState(false);

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  // Use useEffect to set the initial value of aboutMe
  useEffect(() => {
    if (userData && userData.about_me) {
      setAboutMe(userData.about_me); // Fill with the existing about_me if available
    }
  }, [userData]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");
      await axios.post(
        `${baseURL}/api/v1/user/update/${userId}`,
        {
          about_me: aboutMe,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("About Me updated successfully!", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });

      closeModal();
    } catch (error) {
      toast("Failed to update About Me. Please try again.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={true}
      onCancel={closeModal}
      footer={null}
      closeIcon={<IoClose className="text-neutral-50" />}
    >
      <div className="w-full h-auto py-5 px-10 flex flex-col space-y-4">
        <h1 className="text-lg text-neutral-700 font-bold">About Me</h1>
        <TextArea
          rows={6}
          className="border-neutral-500 rounded-2xl"
          placeholder="Write something about yourself..."
          value={aboutMe}
          onChange={(e) => setAboutMe(e.target.value)}
        />
        <span className="text-md text-neutral-400 ml-auto">
          {aboutMe.length}/1000
        </span>

        <button
          className={`w-full p-3 text-white font-bold bg-[#404041] hover:scale-95 rounded-full ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handleSave}
          disabled={loading}
        >
          {loading && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </Modal>
  );
};

export default AboutMeModal;
