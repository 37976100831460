import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Modal, Button, Input } from "antd";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
import bblogo from "../../assets/276pxlogo.svg";
import Postscopy from "../homepage/Postscopy";
import PostShimmer from "../loading/PostShimmer";
import logo from "../../assets/mainlogonew.png";
import { IoClose } from "react-icons/io5";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Toaster, toast } from "sonner";
const SharedPost = () => {
  const { id } = useParams(); // Get post ID from URL
  const [post, setPost] = useState(null); // Initialize post as null
  const navigate = useNavigate();
  const [loadingPost, setLoadingPost] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [url, setUrl] = useState("");
  const [totalPosts, setTotalPosts] = useState(null);

  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [bookmarkedPosts, setBookmarkedPosts] = useState(new Set());
  const [filteredPostCount, setFilteredPostCount] = useState(0);
  console.log(filteredPostCount, "filteredPostCount");
  const [userExists, setUserExists] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [postLimit, setPostLimit] = useState(10);
  const lastApiCallTime = useRef(0);
  const [channelLoading, setChannelLoading] = useState(false);

  useEffect(() => {
    fetchPosts();
  }, [id]);

  const fetchPosts = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId"); // Get userId from localStorage
    setLoadingPost(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/${
          token && userId ? "details" : "share"
        }/${id}`, // Use 'details' if both token and userId are present
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPost(response.data.content); // Assuming the API returns a single post
      setLoadingPost(false);
    } catch (error) {
      console.error("Error fetching post:", error);
      setLoadingPost(false);
    }
  };
  const handleBookmark = async (userId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/profile/bookmark/store`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast(" User bookmarked successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
    } catch (error) {
      console.error("Error bookmarking user:", error);
      toast("Error bookmarking user. Please try again later.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    }
  };

  const handleReportPost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/user-action`,
        { message: "block", post_id: postId, comment_type: "block" },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast("Post reported successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
    } catch (error) {
      console.error("Error reporting post:", error);
      toast("Error reporting post. Please try again later.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    }
  };
  const handleDeletePost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/delete/${postId}`,

        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast(" Post deleted successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
      fetchPosts(true);
    } catch (error) {
      console.error("Error deleted post:", error);
      toast("Error deleted post. Please try again later.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    }
  };

  const createChatChannel = async (postId, toUser) => {
    try {
      setChannelLoading((prev) => ({ ...prev, [postId]: true }));
      const authToken = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/chat/create-channel`;
      const data = {
        reference_id: "",
        reference_type: "support",
        post_id: postId,
        to_user: toUser,
      };

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const channelId = response.data.content.id;

      setChannelLoading((prev) => ({ ...prev, [postId]: false }));

      toast(" Chat channel created successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });

      navigate(`/dm/${channelId}/${toUser}`);
    } catch (error) {
      setChannelLoading((prev) => ({ ...prev, [postId]: false }));
      toast("Error creating chat channel. Please try again later.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    }
  };
  return (
    <div className="w-full paddingy mobile-top">
      <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
        <div className="absolute left-0">
          <img
            src={mobilebarsvg}
            alt="logo"
            className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
            // Ensure setSidebarOpen function is defined or remove it
          />
        </div>
        <div className="flex justify-center w-full">
          <img src={bblogo} className="h-8 hidelarge" alt="Logo" />
        </div>
      </div>

      <hr />
      <div className="w-full p-5 ">
        <IoIosArrowRoundBack
          className="text-3xl cursor-pointer"
          onClick={() => navigate("/")}
        />
      </div>

      {loadingPost ? (
        <div className="">
          <PostShimmer />
        </div>
      ) : post && typeof post === "object" ? (
        <Postscopy
          postdatas={[post]} // Wrap post in an array to match Postscopy's expected prop type
          fetchData={fetchPosts}
          setLoadingpost={setLoadingPost}
          handleBookmark={handleBookmark}
          handleReportPost={handleReportPost}
          createChatChannel={createChatChannel}
          channelLoading={channelLoading}
          handleDeletePost={handleDeletePost}
          setFetching={setFetching}
        />
      ) : (
        <div>No valid post found</div>
      )}
    </div>
  );
};

export default SharedPost;
