import React from "react";

const ShimmerChatPersonDirect = () => {
  return (
    <div className="py-3 bg-white rounded-xl mb-3 w-full px-8">
      <div className="flex animate-pulse">
        {/* Shimmer for avatar */}
        <div className="w-12 h-12 rounded-full bg-neutral-200"></div>

        <div className="flex flex-col ml-2 w-full">
          {/* Shimmer for name */}
          <div className="h-4 bg-neutral-200 rounded w-24 mb-2"></div>

          {/* Shimmer for username */}
          <div className="flex items-center">
            <div className="h-3 bg-neutral-200 rounded w-16"></div>
            <div className="h-3 bg-neutral-200 rounded w-10 ml-2"></div>
          </div>
        </div>
      </div>

      {/* Shimmer for message content */}
      <div className="mt-3 ml-14 mb-3">
        <div className="h-3 bg-neutral-200 rounded w-64 mb-2"></div>
        <div className="h-3 bg-neutral-200 rounded w-48 mb-2"></div>
        <div className="h-3 bg-neutral-200 rounded w-40"></div>
      </div>
      <hr />
    </div>
  );
};

export default ShimmerChatPersonDirect;
