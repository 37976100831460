// CalendarGrid.js
import React from "react";
import moment from "moment";

export default function CalendarGrid({ days, selectedDate, handleDateClick }) {
  return (
    <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
      <div className="grid grid-cols-7 gap-px border-b border-neutral-200 bg-neutral-200 text-center text-xs font-semibold leading-6 text-neutral-700 lg:flex-none">
        {["M", "T", "W", "T", "F", "S", "S"].map((day, idx) => (
          <div key={idx} className="bg-neutral-100 py-1">
            {day}
          </div>
        ))}
      </div>

      <div className="flex bg-neutral-200 text-xs leading-6 text-neutral-700 lg:flex-auto">
        <div className="isolate grid w-full grid-cols-7 grid-rows-5 gap-px">
          {days.map((day) => (
            <button
              key={day.date}
              type="button"
              onClick={() => handleDateClick(day)}
              className={`flex h-14 flex-col px-1 py-2 ${
                day.isCurrentMonth ? "bg-white" : "bg-neutral-50"
              } ${
                day.isToday
                  ? "font-semibold text-[#E7831D]"
                  : day.isCurrentMonth
                  ? "text-neutral-900"
                  : "text-neutral-500"
              } ${
                selectedDate && selectedDate === day.date.format("YYYY-MM-DD")
                  ? "bg-[#E7831D] text-white"
                  : ""
              }`}
            >
              <time
                dateTime={day.date.format("YYYY-MM-DD")}
                className={`flex h-6 w-6 items-center justify-center rounded-full ml-auto ${
                  selectedDate && selectedDate === day.date.format("YYYY-MM-DD")
                    ? "bg-orange-500 text-white"
                    : ""
                }`}
              >
                {day.date.date()}
              </time>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
