import React, { useEffect, useState } from "react";
import SettingsListOne from "./SettingsListOne";
import SettingsListTwo from "./SettingsListTwo";
import SettingsListThree from "./SettingsListThree";
import SocialsTooltip from "./SocialsTooltip";
import { useSelector } from "react-redux";
import translations from "../../languages";
import bblogo from "../../assets/276pxlogo.svg";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
import PleaseLoginModal from "../../pages/PleaseLoginModal";
import SettingsListLang from "./SettingsListLang";
const SettingsMain = ({ setSidebarOpen }) => {
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].headings;
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [userExists, setUserExists] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);

  const handleSettingsClick = () => {
    if (!userExists) {
      setIsLoginModalVisible(true);
    }
  };

  return (
    <div className="scrollbar-hide w-auto flex flex-col paddingy mobile-top">
      <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
        <div className="absolute left-0">
          <img
            src={mobilebarsvg}
            alt="logo"
            className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
        <div className="flex justify-center w-full">
          <img src={bblogo} className="h-8 hidelarge" />
        </div>
      </div>

      <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide">
        {texts.settings}
      </h1>
      <hr />
      <div
        onClick={handleSettingsClick}
        className={`cursor-pointer ${
          !userExists ? "opacity-30 bg-neutral-200" : ""
        }`}
      >
        <SettingsListOne />
      </div>
      <div className="w-full h-2 bg-neutral-100"></div>
      <div
        onClick={handleSettingsClick}
        className={`cursor-pointer ${
          !userExists ? "opacity-30 bg-neutral-200" : ""
        }`}
      >
        <SettingsListTwo />
      </div>
      <div className="w-full h-[0.1rem] bg-neutral-100"></div>
      <SettingsListLang />
      <div className="w-full h-2 bg-neutral-100"></div>
      <SettingsListThree />
      <div className="w-full h-2 bg-neutral-100"></div>
      {/* <SocialsTooltip /> */}
      {/* <footer className="bg-white border-t border-neutral-200  h-1 py-5 ">
        <div className="text-center text-xs text-neutral-500 items-center">
          <a
            href="https://www.bocaenboca.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-neutral-700 mt-3 "
          >
            Boca En Boca is a trademark of The Peddler Group, Inc. Copyright
            2022.
          </a>
        </div>
      </footer> */}
      {isLoginModalVisible && (
        <PleaseLoginModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}
    </div>
  );
};

export default SettingsMain;
