import React from "react";
import ShimmerChatPersonDirect from "./ShimmerChatPersonDirect"; // Assuming it's in the same directory

const ShimmerChatsList = () => {
  return (
    <div className="p-6 flex flex-col gap-6">
      {/* Left Chat Shimmer */}
      <div className="flex">
        <div className="w-3/4 p-6 bg-neutral-100 rounded-xl shimmer-left"></div>
      </div>

      {/* Right Chat Shimmer */}
      <div className="flex justify-end">
        <div className="w-1/4 p-6 bg-neutral-100 rounded-xl shimmer-right"></div>
      </div>

      {/* Another Left Chat Shimmer */}
      <div className="flex">
        <div className="w-5/12 p-6 bg-neutral-100 rounded-xl shimmer-left"></div>
      </div>

      {/* Another Right Chat Shimmer */}
      <div className="flex justify-end">
        <div className="w-1/3 p-6 bg-neutral-100 rounded-xl shimmer-right"></div>
      </div>
      <div className="flex">
        <div className="w-7/12 p-6 bg-neutral-100 rounded-xl shimmer-left"></div>
      </div>

      {/* Right Chat Shimmer */}
      <div className="flex justify-end">
        <div className="w-6/12 p-6 bg-neutral-100 rounded-xl shimmer-right"></div>
      </div>

      {/* Another Left Chat Shimmer */}
      <div className="flex">
        <div className="w-3/4 p-6 bg-neutral-100 rounded-xl shimmer-left"></div>
      </div>

      {/* Right Chat Shimmer */}
      <div className="flex justify-end">
        <div className="w-1/4 p-6 bg-neutral-100 rounded-xl shimmer-right"></div>
      </div>

      {/* Another Left Chat Shimmer */}
      <div className="flex">
        <div className="w-5/12 p-6 bg-neutral-100 rounded-xl shimmer-left"></div>
      </div>

      {/* Another Right Chat Shimmer */}
      <div className="flex justify-end">
        <div className="w-1/3 p-6 bg-neutral-100 rounded-xl shimmer-right"></div>
      </div>
      <div className="flex">
        <div className="w-7/12 p-6 bg-neutral-100 rounded-xl shimmer-left"></div>
      </div>

      {/* Right Chat Shimmer */}
      <div className="flex justify-end">
        <div className="w-6/12 p-6 bg-neutral-100 rounded-xl shimmer-right"></div>
      </div>
      <div className="flex">
        <div className="w-3/4 p-6 bg-neutral-100 rounded-xl shimmer-left"></div>
      </div>

      {/* Right Chat Shimmer */}
      <div className="flex justify-end">
        <div className="w-1/4 p-6 bg-neutral-100 rounded-xl shimmer-right"></div>
      </div>

      {/* Another Left Chat Shimmer */}
      <div className="flex">
        <div className="w-5/12 p-6 bg-neutral-100 rounded-xl shimmer-left"></div>
      </div>

      {/* Another Right Chat Shimmer */}
      <div className="flex justify-end">
        <div className="w-1/3 p-6 bg-neutral-100 rounded-xl shimmer-right"></div>
      </div>
      <div className="flex">
        <div className="w-7/12 p-6 bg-neutral-100 rounded-xl shimmer-left"></div>
      </div>

      {/* Right Chat Shimmer */}
      <div className="flex justify-end">
        <div className="w-6/12 p-6 bg-neutral-100 rounded-xl shimmer-right"></div>
      </div>
    </div>
  );
};

export default ShimmerChatsList;
