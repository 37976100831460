import React, { useState } from "react";
import { Modal, Input, Button, Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import logo from "../assets/mainlogonew.png";
import { IoArrowBackCircleOutline } from "react-icons/io5";
const EmailEnterForgetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [identity, setIdentity] = useState("");
  const navigate = useNavigate();

  const handleModalSubmit = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const identity_type = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(identity)
      ? "email"
      : "phone";

    if (!baseUrl || !identity) {
      toast(" Identity information not found", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/user/forget-password/send-otp`,
        {
          identity,
          identity_type,
        }
      );

      if (response.status === 200) {
        setLoading(false);

        localStorage.setItem(
          "identity",
          JSON.stringify({ identity, identity_type })
        );
        navigate("/verify-otp");
      } else {
        setLoading(false);
        throw new Error("Failed to send OTP");
      }
    } catch (error) {
      setLoading(false);
      toast("Error: " + error.message, {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    }
  };
  return (
    <div className="flex justify-center items-center w-full h-screen overflow-y-auto bg-white">
      <div className="bg-neutral-50 lg:w-4/12 md:w-8/12 w-11/12 rounded-[2rem] p-10 shadow-xl flex text-center flex-col mt-10 overflow-y-auto scrollbar-hide max-h-[90%]">
        <div className="flex w-full">
          <IoArrowBackCircleOutline
            className="text-3xl text-black  justify-start items-start text-left cursor-pointer hover:scale-110  relative top-6"
            onClick={() => navigate("/login")}
          />
        </div>
        <p class="font-inter font-semibold text-2xl leading-7 text-center px-12  mt-5">
          Please enter your email or phone
        </p>

        <img
          src={logo}
          alt="logo"
          className="w-full h-auto px-16 lg:py-8  md:py-10 p-10 mb-4 "
        />

        <input
          placeholder="Enter your email"
          value={identity}
          onChange={(e) => setIdentity(e.target.value)}
          className="bg-neutral-50 p-3 rounded-full mb-2 border-2 border-[#818181] outline-none mt-3 w-full "
        />
        <button
          className="h-14 bg-[#404041] hover:bg-[#585858] text-white font-bold rounded-full shadow-sm text-xl  hover:scale-95 hover:shadow-lg w-full lg:mt-4 mt-0"
          onClick={handleModalSubmit}
        >
          {loading && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}
          Submit
        </button>

        <p className="text-orange-500 text-sm justify-center mx-auto  text-center mt-5 w-10/12">
          Please enter the email associated with your account to change your
          password.
        </p>
      </div>
      <p className="  h-10 fixed bottom-0  mt-3 flex justify-center items-center  space-x-2  w-full   z-50 text-md text-neutral-400 text-[.6rem]">
        {" "}
        <span
          className="  cursor-pointer"
          onClick={() => navigate("/policy/terms_&_conditions")}
        >
          Terms & Conditions
        </span>
        <span
          className=" cursor-pointer "
          onClick={() => navigate("/policy/privacy_policy")}
        >
          Privacy Policy
        </span>
      </p>
    </div>
  );
};

export default EmailEnterForgetPassword;
